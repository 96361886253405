import { TokenService } from '@/api/new/services/storage.service'
import { refreshAccessToken } from '@/api/new/services/tokenRefresh.service'
import i18n from '@/i18n'
import { router } from '@/router/index'
import store from '@/store/store'
import axios from 'axios'

const ApiService = {
  cachedRequests: [],
  init(baseURL) {
    axios.defaults.baseURL = baseURL
    axios.defaults.withCredentials = true
    axios.interceptors.request.use(
      async (req) => {
        const refreshToken = TokenService.getRefreshToken()
        if (
          !refreshToken &&
          !req.isPublicRequest &&
          req.url != 'laas/api/v1/mfa/2fa/verify'
        )
          return
        return req
      },
      (error) => {
        return Promise.reject(error)
      }
    )
    axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const refreshToken = TokenService.getRefreshToken()
        if (error && error.response.status === 401 && refreshToken) {
          if (error.response.data.message === 'The refresh token is invalid.') {
            TokenService.removeAll()
            router.push({ name: 'BaseLogin' })
            store.dispatch(
              'toast/error',
              {
                title: i18n.t('shared.toast.error.title'),
                message: error.response.data.message
              },
              { root: true }
            )
            return Promise.reject(error)
          }
          const req = error.config
          await refreshAccessToken(req, refreshToken)
          return axios(req)
        } else if (
          (error &&
            error.response.status === 403 &&
            error.response.data.errors &&
            Object.keys(error.response.data.errors).includes('otp')) ||
          (error.response.status === 401 && error.response.data.access_token)
        ) {
          store.dispatch(
            'modals/triggerMfaVerificationModal',
            {},
            { root: true }
          )
        } else if (
          error &&
          error.response.status === 422 &&
          error.response.data.message ===
            'The terms and conditions field is required.'
        ) {
          store.dispatch(
            'modals/triggerCooperationAgreementModal',
            {},
            { root: true }
          )
        } else if (
          error &&
          error.response.status === 422 &&
          error.response.data.message ===
            'The current password field is required when email is present.' &&
          (router.currentRoute.value.name === 'clientProfile' ||
            router.currentRoute.value.name === 'supplierProfile')
        ) {
          document
            .getElementById('currentPassword')
            .scrollIntoView({ behavior: 'smooth', block: 'center' })
          const message = i18n.t('shared.errors.missing_password_with_email')
          store.dispatch('toast/warning', { message: message }, { root: true })
          return Promise.reject(error)
        } else if (
          error &&
          error.response.status === 422 &&
          error.response.data.message ===
            'The current password field is required.' &&
          (router.currentRoute.value.name === 'clientProfile' ||
            router.currentRoute.value.name === 'supplierProfile')
        ) {
          document
            .getElementById('currentPassword')
            .scrollIntoView({ behavior: 'smooth', block: 'center' })
          const message = i18n.t('shared.errors.missing_password')
          store.dispatch('toast/warning', { message: message }, { root: true })
          return Promise.reject(error)
        } else if (error.response.data.localization) {
          const message =
            error.response.data.localization.key &&
            i18n.t(
              error.response.data.localization.key,
              error.response.data.localization.variables
            ) !== error.response.data.localization.key
              ? i18n.t(
                  error.response.data.localization.key,
                  error.response.data.localization.variables
                )
              : error.response.data.data.message
          store.dispatch('toast/warning', { message: message }, { root: true })
          return Promise.reject(error)
        } else if (
          (error && error.response.status === 500) ||
          (error && error.response.status === 404)
        ) {
          store.dispatch(
            'toast/error',
            {
              title: i18n.t('shared.toast.error.title'),
              message: error.response.data.message
            },
            { root: true }
          )
          return Promise.reject(error)
        } else {
          store.dispatch(
            'toast/warning',
            {
              title: i18n.t('shared.toast.warning.title'),
              message: error.response.data.message
            },
            { root: true }
          )
          return Promise.reject(error)
        }
      }
    )
  },
  get(resource, params) {
    if (params) {
      return axios.get(`laas/api/v1/${resource}`, params)
    } else {
      return axios.get(`laas/api/v1/${resource}`)
    }
  },
  post(resource, data) {
    if (
      !this.cachedRequests.includes(resource) ||
      resource.includes('content-pages')
    ) {
      this.cachedRequests.push(resource)
      setTimeout(() => {
        this.cachedRequests = []
      }, 500)
      return axios.post(`laas/api/v1/${resource}`, data)
    } else {
      this.cachedRequests = []
      return Promise.reject({
        response: {
          data: {
            message: 'Duplicated request was stopped'
          }
        }
      })
    }
  },
  postFormData(resource, data) {
    if (!this.cachedRequests.includes(resource)) {
      this.cachedRequests.push(resource)
      setTimeout(() => {
        this.cachedRequests = []
      }, 500)
      return axios.post(`laas/api/v1/${resource}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    } else {
      this.cachedRequests = []
      return Promise.reject({
        response: {
          data: {
            message: 'Duplicated request was stopped'
          }
        }
      })
    }
  },
  put(resource, data) {
    return axios.put(`laas/api/v1/${resource}`, data)
  },
  patch(resource, data) {
    return axios.patch(`laas/api/v1/${resource}`, data)
  },
  delete(resource) {
    return axios.delete(`laas/api/v1/${resource}`)
  },
  /**
   * Perform a custom Axios request.
   *
   * data is an object containing the following properties:
   *  - method
   *  - url
   *  - data ... request payload
   *  - auth (optional)
   *    - username
   *    - password
   **/
  customRequest(data) {
    return axios(data)
  },
  publicPost(resource, data) {
    if (!this.cachedRequests.includes(resource)) {
      this.cachedRequests.push(resource)
      setTimeout(() => {
        this.cachedRequests = []
      }, 500)
      return axios.post(`laas/api/v1/${resource}`, data, {
        isPublicRequest: true,
        withCredentials: false
      })
    } else {
      this.cachedRequests = []
      return Promise.reject({
        response: {
          data: {
            message: 'Duplicated request was stopped'
          }
        }
      })
    }
  },
  publicGet(resource, params) {
    if (params) {
      return axios.get(`laas/api/v1/${resource}`, {
        params: params.params,
        isPublicRequest: true,
        withCredentials: false
      })
    } else {
      return axios.get(`laas/api/v1/${resource}`, {
        isPublicRequest: true,
        withCredentials: false
      })
    }
  },
  publicToken(data) {
    return axios.post('oauth/token', data, {
      isPublicRequest: true,
      withCredentials: false
    })
  },
  publicLogin(data) {
    return axios.post('oauth/login', data, {
      isPublicRequest: true
    })
  },
  logout() {
    return axios.post('oauth/logout')
  },
  personalAccessTokens(data) {
    return axios.post('oauth/personal-access-tokens', data)
  }
}

export default ApiService
