import axios from 'axios'

const ApiServiceV2 = {
  cachedRequests: [],
  init(baseURL) {
    axios.defaults.baseURL = baseURL
    axios.defaults.withCredentials = true
  },
  get(resource, params) {
    if (params) {
      return axios.get(`laas/api/v2/${resource}`, params)
    } else {
      return axios.get(`laas/api/v2/${resource}`)
    }
  },
  post(resource, data) {
    if (!this.cachedRequests.includes(resource)) {
      this.cachedRequests.push(resource)
      setTimeout(() => {
        this.cachedRequests = []
      }, 500)
      return axios.post(`laas/api/v2/${resource}`, data)
    } else {
      this.cachedRequests = []
      return Promise.reject({
        response: {
          data: {
            message: 'Duplicated request was stopped'
          }
        }
      })
    }
  },
  postFormData(resource, data) {
    if (!this.cachedRequests.includes(resource)) {
      this.cachedRequests.push(resource)
      setTimeout(() => {
        this.cachedRequests = []
      }, 500)
      return axios.post(`laas/api/v2/${resource}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    } else {
      this.cachedRequests = []
      return Promise.reject({
        response: {
          data: {
            message: 'Duplicated request was stopped'
          }
        }
      })
    }
  },
  put(resource, data) {
    return axios.put(`laas/api/v2/${resource}`, data)
  },
  patch(resource, data) {
    return axios.patch(`laas/api/v2/${resource}`, data)
  },
  delete(resource) {
    return axios.delete(`laas/api/v2/${resource}`)
  },
  /**
   * Perform a custom Axios request.
   *
   * data is an object containing the following properties:
   *  - method
   *  - url
   *  - data ... request payload
   *  - auth (optional)
   *    - username
   *    - password
   **/
  customRequest(data) {
    return axios(data)
  }
}

export default ApiServiceV2
